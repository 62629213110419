<template>
  <div>
    <div class="w-screen section-container laybare-services-banner relative space-top" v-if="hasNewSeasonalPage"></div>

    <div class="w-screen section-bg relative flex items-baseline xl:items-center space-top" v-else>
      <div class="absolute bottom-0 right-2 xl:top-60 xl:right-24 2xl:right-40">
        <img class="w-80 md:w-106 xl:w-124 2xl:w-full" src="../../assets/images/services-page/generic-banners/lb-text.png" alt="generic-lb-description">
      </div>
      <img class="section-img -ml-16 md:-ml-28 xl:ml-0" src="../../assets/images/services-page/generic-banners/lb-model.png" alt="generic-lb-model">
    </div>

    <div class="w-screen service-we-offer px-5 py-9 mobileL:p-14 xl:p-16 2xl:p-20" id="service-we-offer" >
        <div class="content-wrapper max-w-xl mobileL:max-w-2xl xl:max-w-5xl 2xl:max-w-7xl mx-auto text-center text-white-1">
            <h3 class="font-playlist mb-6 mobileL:mb-0 service-we-offer-content">Services we offer</h3>
            <p class="text-base xl:text-2xl 2xl:text-3xl">Browse our full line of services ranging from facial and body
            waxing using our patented cold wax jelly - Through Care. This cold
            wax formulation naturally pulls the hair from the roots and leaves
            only smooth skin behind.</p>
        </div>
    </div>

    <div class="w-full text-brown-2 services-bg py-10 px-2 mobileL:px-0 ">
      <div class="flex text-center items-center mb-5 ">
        <div class="bg-brown-2 w-full h-0.5 mobileL:h-1"></div>
        <div>
          <h2 class="font-bold text-base whitespace-nowrap mx-5 mobileL:mx-8 mobileL:text-4xl xl:text-5xl 2xl:text-6xl tracking-widest">MALE SERVICES</h2>
        </div>
        <div class="bg-brown-2 w-full h-0.5 mobileL:h-1"></div>
      </div>

      <div class="w-full mobileL:w-10/12 mobileL:mx-auto mb-5 ">
        <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
          <ServiceCard v-for="(service, index) in maleServices" :key="index" :service="service" />
        </div>
        <center class="mt-8" v-if="malePage !== lbMaleServicePageCount">
          <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData('male')">See More</button>
        </center>
      </div>

      <div class="flex text-center items-center mb-5">
        <div class="bg-brown-2 w-full h-0.5 mobileL:h-1"></div>
        <div>
          <h2 class="font-bold text-base whitespace-nowrap mx-5 mobileL:mx-8 mobileL:text-3xl xl:text-4xl 2xl:text-6xl tracking-widest">FEMALE SERVICES</h2>
        </div>
        <div class="bg-brown-2 w-full h-0.5 mobileL:h-1"></div>
      </div>

      <div class="w-full mobileL:w-10/12 mx-auto mb-5">
        <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
          <ServiceCard v-for="(service, index) in femaleServices" :key="index" :service="service" />
        </div>
        <center class="mt-8" v-if="femalePage !== lbFemaleServicePageCount">
          <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData('female')">See More</button>
        </center>
      </div>
    </div>

    <div class="w-screen bg-brown-8 py-10 px-5 mobileL:px-0 text-white-1"  id="our-packages">
      <div class="flex text-center items-center mb-5">
        <div class="bg-white-1 w-full h-0.5 mobileL:h-1"></div>
        <h2 class="font-bold text-base whitespace-nowrap mx-5 mobileL:mx-8 mobileL:text-3xl xl:text-4xl 2xl:text-6xl tracking-widest">OUR PACKAGES</h2>
        <div class="bg-white-1 w-full h-0.5 mobileL:h-1"></div>
      </div>

      <div class="w-full mobileL:w-10/12 mx-auto mb-5">
        <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
          <ServiceCard v-for="(servicePackage, index) in packages" :key="index" :service="servicePackage" />
        </div>
        <center class="mt-8" v-if="packagePage !== lbPackagePageCount">
          <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData()">See More</button>
        </center>
      </div>
    </div>

    <div class="w-screen bg-brown-2 py-7 xl:py-10 text-white-1" id="know-before-you-go">
      <h2 class="font-bold text-4xl mobileL:text-5xl xl:text-6xl 2xl:text-7xl tracking-wides font-tahu text-center know-before-you-go-section">Know Before You Go</h2>

      <div class="w-10/12 mx-auto">
        <div class="grid grid-cols-2 mobileL:grid-cols-4 justify-center text-center gap-5">
          <div class="w-full">
            <img class="w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto mb-6" src="../../assets/images/services-page/lb-services-cream.png" alt="Cream">
            <h2 class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-2xl">Exfoliate 1 week before your service</h2>
          </div>
          <div class="w-full">
            <img class="w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto mb-6" src="../../assets/images/services-page/lb-services-stopwatch.png" alt="Cream">
            <h2 class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-2xl">Wait at least 1 week after your period</h2>
          </div>
          <div class="w-full">
            <img class="w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto mb-6" src="../../assets/images/services-page/lb-services-skirt.png" alt="Cream">
            <h2 class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-2xl">Wear loose or breathable clothing</h2>
          </div>
          <div class="w-full">
            <img class="w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto mb-6" src="../../assets/images/services-page/lb-services-coffee.png" alt="Cream">
            <h2 class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-2xl">Skip the caffeine</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ServiceCard from '../../components/ServiceCard.vue';
export default {
  data() {
    return {
      malePage: 1,
      femalePage: 1,
      packagePage: 1,
      maleServices: [],
      femaleServices: [],
      packages: [],
      hasNewSeasonalPage:true,
      requestLimit: 8,
      maleServiceCurrentPage: 1
    }
  },

  components: {
    ServiceCard
  },

  methods: {
    ...mapActions('services', [
      'fetchServices',
      'fetchPackages',
      'fetchBusinessUnit',
    ]),

    loadMoreData(serviceType) {
      if (serviceType === 'male') {
        this.malePage += 1;
        this.fetchServices({ business_unit_id: this.$layBareId, only_gender: 'male', page: this.malePage, limit: this.requestLimit});
      } else if(serviceType === 'female') {
        this.femalePage += 1;
        this.fetchServices({ business_unit_id: this.$layBareId, only_gender: 'female', page: this.femalePage, limit: this.requestLimit});
      } else {
        this.packagePage += 1;
        this.fetchPackages({business_unit_id: this.$layBareId, page: this.packagePage, limit: this.requestLimit})
      }
    }
  },

  computed: {
    ...mapGetters('services',
      ['allService',
      'allPackage',
      'allBusinessUnit',
      'allFemaleService',
      'allMaleService',
      'lbMaleServicePageCount',
      'lbFemaleServicePageCount',
      'lbPackagePageCount'
      ]
    ),
  },

  created() {
    this.fetchServices({business_unit_id: this.$layBareId, only_gender: 'male', limit: this.requestLimit});
    this.fetchServices({business_unit_id: this.$layBareId, only_gender: 'female', limit: this.requestLimit});
    this.fetchPackages({business_unit_id: this.$layBareId, limit: this.requestLimit});
  },

  watch: {
    allMaleService: {
      immediate: true,
      handler: function(services) {
        if (!services.length) return;

        this.maleServices = services;
      }
    },

    allFemaleService: {
      immediate: true,
      handler: function(services) {
        if (!services.length) return;

        this.femaleServices = services;
      }
    },

    allPackage: {
      immediate: true,
      handler: function (packages) {
        if (!packages.length) return;

        this.packages = packages;
      }
    }
  }
}
</script>

<style scoped>
.section-container {
  height: 600px;
}

.laybare-services-banner {
  background: url('../../assets/images/services-page/seasonal-banners/LB_DEC_BANNER.jpg') no-repeat;
  background-size: 100% 100%;
}

.section-bg{
  background: url('../../assets/images/services-page/generic-banners/lb-background.jpg') no-repeat;
  background-size: cover;
}

.section-img{
  width:750px;
}

.space-top {
  margin-top: 90px;
}

.service-we-offer {
    background: url('../../assets/images/home-page/when-you-see-hair-bg.jpg') no-repeat;
    background-size: cover;
}

.service-we-offer-content {
    font-size: 4.25rem;
    text-shadow: 6px 6px #6CB93C;
}

.services-bg {
  background: url('../../assets/images/home-page/body-background.jpg') no-repeat;
  background-size: cover;
}

.know-before-you-go-section {
  text-shadow: 6px 6px #6e554281;
}

@media (max-width: 480px) {


    .section-container {
      height:256px;
    }

    .laybare-services-banner {
      background: url('../../assets/images/services-page/seasonal-banners/LB_DEC_BANNER.jpg') no-repeat;
      background-size: 100% 100%;
    }
    
    .section-description{
      width:280px;
    }

    .section-img{
      width:420px;
    }

    .space-top {
      margin-top: 60px;
    }

    .service-we-offer-content {
    font-size: 36px;
    text-shadow: 6px 6px #6CB93C;
    }
}

@media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .section-container {
      height: calc(100vh - 100px);
    }

    .service-we-offer-content {
      font-size: 5.25rem;
      text-shadow: 5px 5px #6CB93C;
    }
}

@media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }

    .service-we-offer-content {
      font-size: 6.25rem;
      text-shadow: 4px 4px #6CB93C;
    }
  }
</style>